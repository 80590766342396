import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  Fab,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LoopIcon from "@mui/icons-material/Loop";
import NewPostDialog from "./newPost";

const formatDate = (date) => {
  return new Date(date).toLocaleString();
};

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [newPostOpen, setNewPostOpen] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [fetchAlertOpen, setFetchAlertOpen] = React.useState(false);
  const [postAlertOpen, setPostAlertOpen] = React.useState(false);

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFetchAlertOpen(false);
    setPostAlertOpen(false);
  };

  const addPost = () => {
    setNewPostOpen(true);
  };

  const handleClose = () => {
    setNewPostOpen(false);

    // reload posts after potentially creating new one
    setReload(!reload);
  };

  const getPosts = async () => {
    const resp = await fetch(
      "https://ga_worker.jabendro.workers.dev/posts",
    )?.catch((err) => {
      console.error(err);
      setFetchAlertOpen(true);
    });
    const postsResp = await resp?.json();

    setPosts(postsResp);
  };

  useEffect(() => {
    getPosts();
  }, [reload]);

  return (
    <div>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ m: 2 }}
      >
        {posts?.map((post) => (
          <Grid item xs={2} sm={4} md={4} key={post.publishDate}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography variant="h5">{post.title}</Typography>
                <Typography sx={{ fontSize: 11 }} color="text.secondary">
                  @{post.username}
                </Typography>
                <Typography sx={{ fontSize: 11 }} color="text.secondary">
                  {formatDate(post.publishDate)}
                </Typography>
                <Typography sx={{ mt: 2 }} variant="body2">
                  {post.content}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item>
          <Card>
            <CardContent>
              <Fab color="primary" aria-label="add">
                <AddIcon onClick={addPost} />
              </Fab>
            </CardContent>
            <CardActions>
              <IconButton aria-label="refresh" size="small" onClick={getPosts}>
                <LoopIcon fontSize="inherit" />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <NewPostDialog
        isOpen={newPostOpen}
        closeDialog={handleClose}
        posts={posts}
        setPosts={setPosts}
        setAlertOpen={setPostAlertOpen}
      ></NewPostDialog>

      <Snackbar
        open={fetchAlertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          Error fetching posts from Cloudflare Workers KV :(
        </Alert>
      </Snackbar>

      <Snackbar
        open={postAlertOpen}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert onClose={closeAlert} severity="error" sx={{ width: "100%" }}>
          Error sending new post to Cloudflare Workers KV :(
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Posts;
