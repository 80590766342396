import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const NewPostDialog = (props) => {
  const { isOpen, closeDialog, posts, setPosts, setAlertOpen } = props;

  let newPost = { title: "", content: "", username: "" };
  const [postData, setPostData] = React.useState(newPost);
  const [canSubmit, setCanSubmit] = React.useState(false);

  const handleClose = () => {
    closeDialog();

    // clear form on close
    setPostData(newPost);
    setCanSubmit(false);
  };

  const updateForm = (formField, value) => {
    setPostData({ ...postData, [formField]: value || "" });

    if (postData.username === "" || postData.title === "") {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  };

  const submitPost = () => {
    /* prevent blank posts */
    if (postData.username === "" || postData.title === "") {
      handleClose();
      return;
    }

    /* form idea taken from: https://mattboldt.com/2020/05/02/formdata-with-react-hooks-and-fetch/ */
    fetch("https://ga_worker.jabendro.workers.dev/posts", {
      method: "POST",
      body: JSON.stringify(postData),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        // optimistically update posts with new post since cloudflare workers takes a second to start returning the new post
        setPosts([...posts, postData]);
      })
      .catch((err) => {
        console.error(err);
        setAlertOpen(true);
      });

    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Add New Post</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}
        >
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            onChange={(event) => updateForm("username", event.target.value)}
          />
          <TextField
            id="title"
            label="Title"
            variant="outlined"
            onChange={(event) => updateForm("title", event.target.value)}
          />
          <TextField
            id="content"
            label="Content"
            multiline
            maxRows={5}
            onChange={(event) => updateForm("content", event.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={submitPost} disabled={!canSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewPostDialog;
